import React, { useState } from 'react';
import Slider from 'react-slick';
import Logo1 from '../img/APHRC.jpg';
import Logo2 from '../img/cigna.jpg';
import Logo3 from '../img/giz.jpg';
import Logo4 from '../img/ronn.jpg';
import Logo5 from '../img/huduma.jpg';
import Logo6 from '../img/moh.jpg';
import Logo7 from '../img/klrc.jpg';
import Logo8 from '../img/apollo.jpg';
import Logo9 from '../img/jambo.jpg';
import Logo10 from '../img/monarch.jpg';
import Logo11 from '../img/ogembo.jpg';
import Logo12 from '../img/aura.jpg';
import Logo13 from '../img/mogas.jpg';
import Logo14 from '../img/kata.jpg';
import Logo15 from '../img/servier.jpg';
import Logo16 from '../img/knh.jpg';
import './Slider.scss';

const LogoSlider = () => {
    const [settings] = useState({
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
      cssEase: 'linear',
    });  
    
    const logos = [
        Logo1,
        Logo2,
        Logo3,
        Logo4,
        Logo5,
        Logo6,
        Logo7,
        Logo8,
        Logo9,
        Logo10,
        Logo11,
        Logo12,
        Logo13,
        Logo14,
        Logo15,
        Logo16,
      ];
    
    return (
      <div className='testimonials'>
        <Slider {...settings}>
        {logos.map((logo, index) => (
          <div key={index} className="container">
            <img src={logo} alt='logo'/>
          </div>
          ))}
        </Slider>
      </div>
    );
  };
  
export default LogoSlider;
